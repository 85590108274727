// // Bootstrap Breakpoints for max-width
$breakpoints: (
  xs: 575.98px,
  // Upper limit for xs
  sm: 767.98px,
  // Upper limit for sm
  lg: 991.98px,
  // Upper limit for lg
  xl: 1199.98px,
  // Upper limit for xl
  xxl: 3000.98px // Upper limit for xxl
);
//Pc xxl 1399.98px 
$withboardxxl: 47vw;
$infor-height: 19vw;
$logo-width: 8vw;
$cardwidth: 20vw;
$image-category: 3vw;
$card-img: 10vw;
$piecat: 17vw;
$categories-width: 27rem;
$menu-max-height: 36vw;
$cardhood-width: 27vw;

$cardhood-width-xxl-landscape: 30vw;
$cardhood-margin-left-xxl-landscape: 17vw;
$cg-wrap-margin-left-xxl-landscape: 1vw;
$editboardxxl: 44vw;
//Pc  1399.98px xxl 

//360x640 Galaxy S5  xs portrait
$withboardxs: 87vw; // 575.98px
$cardhood-max-width-xs: 100%;
$navbarhood-margintop-sm-portrait: -4.2rem;
$cg-wrap-margin-top-sm-portrait: 18vw;
$logo-margin-left-sm-portrait: 18em;
$logo-width-sm-portrait: 8vw;
$logo-text-margin-left-sm-portrait: 14vw;
$infor-height-xs: 25vh;
$ratings-cards-margin-top: -92vw;
$ratings-cards-margin-bottom: 50vh;
$cardbottons-margin-top-xs: -145vw;
//360x640 Galaxy S5


$withboardxl: 80vw; //   1199.98px
$withboardlg: 70vw; // 991.98px
$withboardsm: 60vw; //   767.98px

$withboard: 20vw; // 575.98px
$withboardxxs: 85vw; // 575.98px
//landscape board
$withboardxxllandscape: 60vw; //1399.98px
$withboardxllandscape: 65vw; //  1199.98px
$withboardlglandscape: 40vw; //  991.98px
$withboardsmlandscape: 45vw; //  767.98px
$withboardxslandscape: 90vw; //  575.98px
$withboardlandscape: 50vw; //- 575.98px
$withboardxxslandscape: 60vw; // 575.98px

//Colors bacgrounds
$body-bg: #185261;
$category-item1-bg: $body-bg;
$card_bg: $body-bg;
$card-title-bg: #5a5a5ab5;

$scrollbar-ripe-malinka: #457737;
$scrollbar-ripe-malinka: linear-gradient(120deg, #030003 0%, #330a10 100%);

//borders
$card-title-border-bg: #f1bf08;

$border: none;
//Text color
$body-text-color: rgb(223, 236, 219);
$Black-play-color: #000000a7;
$h2: #e76434;
$h3: #00000097;
//measures


body {

  background-color: $body-bg;

  font-family: Libre Baskerville;
  color: $body-text-color;


}

html,
body {
  max-width: 96%;
  overflow-x: hidden;
}

p {
  font-size: 1.4em;
  /* Adjusts the size of the font */
  // letter-spacing: 0.2vw;
  /* Increases the spacing between letters */
}

.infor {

  max-height: $infor-height;
  overflow-y: auto;
  /* Enables vertical scrolling if content exceeds 100px */
}

.spare-pieces {
  display: inline-flex;
  max-width: 47vw;

}

.boardeditor .cg-wrap {

  width: $editboardxxl !important;
  height: $editboardxxl !important;
}

.piece {


  background-repeat: no-repeat;
  background-size: contain;
  /* or cover */
}

//finish Landscape
.scrollbar-ripe-malinka::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(190, 26, 26, 0.1);
  background-color: $scrollbar-ripe-malinka;

  border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
  width: 12px;
  background-color: $body-bg;
  border-color: green;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #073d17 0%, #d2e108 100%);
  background-image: $scrollbar-ripe-malinka;

}

.example-1 {
  position: relative;
  overflow-y: scroll;
  height: $infor-height;

  width: $categories-width;
  border: $border;
}

.ratings {
  font-size: 0.9vw !important;
  text-align: center;
}

.card {
  border-color: $card-title-border-bg;
  border-radius: 1.5rem;
  color: $body-text-color;
  background-color: $card_bg;
  // width: $cardwidth;

}

.card-title {

  background-color: $card-title-bg;
  color: $body-text-color;

  border-color: $card-title-border-bg;
  border-radius: 1.5rem;
}

.category-item1 {

  background-color: $category-item1-bg;
}

.medal {
  width: 4vw;
}

.puzzle-rating {
  width: 3vw;

}

.puzzle-categories {
  text-align: center;
}

.logo {
  margin-left: 2em;
  margin-bottom: 1em;
  width: $logo-width;
  height: 100%;
}

.logo-text {
  font-weight: 800;
  color: $h2;
}

.image-category {

  width: $image-category;
  height: 100%;
}

.card-img {

  width: $card-img;
  height: 100%;
}

.piecat {

  width: $piecat;
  height: 100%;
  padding-left: 2em;

}



.card {
  text-align: center;
}

.title-cat-img {
  text-align: center;
}

.text-center {
  text-align: center;
}

h2 {
  color: $h2;
}

h3 {
  color: $h3
}


.Black {
  background-color: $body-bg;
  color: $Black-play-color;
}

.White {
  background-color: $body-bg;
  color: $body-text-color;
}

.customCanvasBackgroundColor {
  background-color: $body-bg;
}

.cg-wrap {
  border-color: red;
}

.chessboard {
  border-color: red;
  width: $withboard;
  height: $withboard;
}

.menu-after-logo {
  max-height: $menu-max-height;
  overflow-y: auto;


}



//scrool menu
.menu-after-logo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(190, 26, 26, 0.1);
  background-color: $scrollbar-ripe-malinka;

  border-radius: 10px;
}

.menu-after-logo::-webkit-scrollbar {
  width: 12px;
  background-color: $body-bg;
  border-color: green;
}

.menu-after-logo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #073d17 0%, #d2e108 100%);
  background-image: $scrollbar-ripe-malinka;

}





.navbar {
  color: #d2e108;

}

.cardhood {

  background-color: $body-bg;
  max-width: $cardhood-width;
}

@media screen and (max-width: map-get($breakpoints, 'xxl')) {
  .cardhood {

    background-color: $body-bg;
    max-width: $cardhood-width-xxl-landscape;

    margin-left: $cardhood-margin-left-xxl-landscape !important;

  }

  .cg-wrap {

    margin-left: $cg-wrap-margin-left-xxl-landscape !important;
    width: $withboardxxl !important;
    height: $withboardxxl !important;
  }
}

@media screen and (max-width: map-get($breakpoints, 'xl')) {

  .cg-wrap {
    width: 22px !important;
    height: $withboardxl !important;
    left: -2vw !important;
  }
}

@media screen and (max-width: map-get($breakpoints, 'lg')) {
  .cg-wrap {
    width: $withboardlg !important;
    height: $withboardlg !important;
  }
}

@media screen and (max-width: map-get($breakpoints, 'sm')) {
  .logo-text {

    margin-left: $logo-text-margin-left-sm-portrait;
  }

  .logo {

    width: $logo-width-sm-portrait;
    float: right;
  }

  .navbarhood {

    margin-top: $navbarhood-margintop-sm-portrait !important;
  }

  .cg-wrap {

    margin-top: $cg-wrap-margin-top-sm-portrait !important;
    width: $withboardsm !important;
    height: $withboardsm !important;
  }
}

@media screen and (max-width: map-get($breakpoints, 'xs')) {

  .infohood {
    margin-top: 65vw !important;
  }

  .medal {
    width: 14vw;
  }


  .ratings {

    font-size: 4vw !important;
    text-align: center;
    font-weight: 700;
  }

  .ratings-cards {

    margin-top: $ratings-cards-margin-top !important;
    margin-bottom: $ratings-cards-margin-bottom !important;
  }

  .puzzle-rating {
    width: 10vw;
  }

  .cardhood {

    background-color: $body-bg;
    max-width: 100% !important;

    margin-left: 0 !important;

  }

  .cg-wrap {
    width: $withboardxs !important;
    height: $withboardxs !important;

  }

  .example-1 {

    height: $infor-height-xs;
    width: 27rem;

  }

  .cardbottons {

    margin-top: $cardbottons-margin-top-xs !important;


  }


}




/*styles for landscape*/
/*styles for landscape*/
/*styles for landscape*/
@media screen and (max-width: 1700px) and (orientation: landscape) {
  body {
    background-color: $body-bg;
    color: $body-text-color;

  }

  .cg-wrap {
    width: 50vw; // Smaller width for smaller screens in landscape orientation
    height: 50vw; // Smaller height for smaller screens in landscape orientation
  }

  @media screen and (max-width: map-get($breakpoints, 'xxl')) {
    .cg-wrap {
      width: $withboardxxllandscape !important;
      height: $withboardxxllandscape !important;
    }
  }

  @media screen and (max-width: map-get($breakpoints, 'xl')) {
    .cg-wrap {
      width: $withboardxllandscape !important;
      height: $withboardxllandscape !important;
      // left: 20vw !important;
      // margin-top: -5vw !important;
    }
  }

  @media screen and (max-width: map-get($breakpoints, 'lg')) {
    .cg-wrap {
      margin-left: 6vw !important;
      $withboardlglandscape-lg: 40vw;
      width: $withboardlglandscape-lg !important;
      height: $withboardlglandscape-lg !important;
    }

    .infohood {
      margin-top: 0vw !important;
    }

    .medal {
      width: 14vw;
    }


    .ratings {

      font-size: 4vw !important;
      text-align: center;
      font-weight: 700;
    }

    .ratings-cards {

      margin-top: -20vw !important;
      margin-bottom: 0vw !important;
    }

    .puzzle-rating {
      width: 10vw;
    }

    .cardhood {
      background-color: $body-bg;
      max-width: 31vw !important;
      margin-left: 23vw !important;
    }

    .example-1 {

      height: $infor-height-xs;
      width: 27rem;

    }

    .cardbottons {

      margin-top: $cardbottons-margin-top-xs !important;


    }

  }

  @media screen and (max-width: map-get($breakpoints, 'sm')) {


    .cg-wrap {
      // margin-left: 20vw !important;
      width: $withboardsmlandscape !important;
      height: $withboardsmlandscape !important;
    }
  }

  @media screen and (max-width: map-get($breakpoints, 'xs')) {


    .cg-wrap {
      // margin-left: 70vw !important;
      width: $withboardsmlandscape !important;
      height: $withboardsmlandscape !important;
    }
  }

}


.textheader {

  font-weight: 700;
  color: #d7ccc8;
  text-align: center;
  margin-top: 1vw;
  margin-bottom: 3vw;
  margin-left: 6vw;
}

.table {
  width: 100%;
  /* Full-width table */
  font-size: 18px;
  /* Larger font size */
}

.table th {
  background-color: #5a5a5a !important;
}

.table th,
.table td {

  padding: 1vw;
  /* Increased padding for cells */
  text-align: left;
  /* Align text to the left */
}

.row-color-0 {
  background-color: #39af6c !important;
  /* light red */
}

.row-color-1 {
  background-color: #d48953 !important;
  /* light green */
}

.row-color-2 {
  background-color: #b6a12c !important;
  /* light blue */
}

img {
  width: 100%;
  padding: 0 0 2vw 0;

}

.tornamentHam {
  width: 60%;
  margin-left: 20%;

}

hr {
  border: 2px;
  height: 15px;
  background-image: linear-gradient(to right, rgb(244, 19, 7), rgb(239, 251, 10), rgb(244, 7, 7));
  box-shadow: 20px 10px 5px rgba(0, 0, 0, 0.5);
}

.eventbriteButton {
  background-color: #f1bf08;
}

.modalTitle {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: rgb(77, 135, 42);
  text-align: center;
}


.modalContent {
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: rgb(22, 17, 9);
}